@font-face {
  font-family: 'NotoSans';
  src: url("../../Assets/Fonts/NotoSans-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBold';
  src: url("../../Assets/Fonts/NotoSans-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansItalic';
  src: url("../../Assets/Fonts/NotoSans-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBoldItalic';
  src: url("../../Assets/Fonts/NotoSans-BoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerif';
  src: url("../../Assets/Fonts/NotoSerif-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBold';
  src: url("../../Assets/Fonts/NotoSerif-Bold.ttf") format("truetype");
  font-style: normal; }

@font-face {
  font-family: 'NotoSerifItalic';
  src: url("../../Assets/Fonts/NotoSerif-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBoldItalic';
  src: url("../../Assets/Fonts/NotoSerif-BoldItalic.ttf") format("truetype"); }

@keyframes grow {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes slide-in-left {
  0% {
    opacity: 0;
    transform: translateX(-1rem); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes slide-down {
  0% {
    opacity: 0.5;
    transform: translateY(-1rem); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slide-down-small {
  0% {
    opacity: 0;
    transform: translateY(-10px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slide-up {
  0% {
    opacity: 0.5;
    transform: translateY(15px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in-from-none {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes slide-up-from-none {
  0% {
    opacity: 0;
    transform: translateY(15px); }
  50% {
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes bounce {
  50% {
    padding-top: 0.5rem; }
  100% {
    padding-top: 0; } }

.tab-strip {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dcdfe1 !important;
  width: 100%; }
  .tab-strip .tab-strip-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent !important;
    border-radius: 0px !important;
    position: relative;
    border: none !important;
    padding: 0px !important;
    color: #071522 !important;
    margin: 0px 30px 0px 0px !important;
    height: 100%;
    opacity: 0.8; }
    .tab-strip .tab-strip-tab:focus {
      outline: none !important;
      opacity: 1;
      box-shadow: 0px -3px #eff0f1 !important; }
    .tab-strip .tab-strip-tab .active-underline {
      width: 0px;
      position: absolute;
      bottom: 0;
      background-color: #3498db;
      height: 5px; }
    .tab-strip .tab-strip-tab.active {
      opacity: 1; }
      .tab-strip .tab-strip-tab.active .active-underline {
        -moz-transition: width 0.2s ease;
        -o-transition: width 0.2s ease;
        -webkit-transition: width 0.2s ease;
        transition: width 0.2s ease;
        width: 100%; }
    .tab-strip .tab-strip-tab:hover {
      opacity: 1; }
  .tab-strip.tab-strip-lg {
    height: 45px; }
    .tab-strip.tab-strip-lg .tab-strip-tab {
      font-family: NotoSansBold;
      font-size: 18px;
      height: 45px; }
  .tab-strip.tab-strip-sm {
    height: 32px; }
    .tab-strip.tab-strip-sm .tab-strip-tab {
      font-family: NotoSansBold;
      font-size: 16px;
      padding-bottom: 5px !important; }
