@font-face {
  font-family: 'NotoSans';
  src: url("../../../Assets/Fonts/NotoSans-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBold';
  src: url("../../../Assets/Fonts/NotoSans-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansItalic';
  src: url("../../../Assets/Fonts/NotoSans-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBoldItalic';
  src: url("../../../Assets/Fonts/NotoSans-BoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerif';
  src: url("../../../Assets/Fonts/NotoSerif-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBold';
  src: url("../../../Assets/Fonts/NotoSerif-Bold.ttf") format("truetype");
  font-style: normal; }

@font-face {
  font-family: 'NotoSerifItalic';
  src: url("../../../Assets/Fonts/NotoSerif-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBoldItalic';
  src: url("../../../Assets/Fonts/NotoSerif-BoldItalic.ttf") format("truetype"); }

.form-error-section {
  display: flex;
  position: relative;
  border-width: 0.5px 0.5px 0.5px 4px;
  border-color: #BD0707;
  border-style: solid;
  border-radius: 4px;
  background-color: #F8E6E6;
  margin-bottom: 40px;
  padding: 16px 16px 0px 20px; }
  .form-error-section .title {
    font-weight: 700;
    font-family: NotoSansBold;
    margin-bottom: 8px; }
  .form-error-section ul {
    list-style: none;
    padding-left: 0; }
  .form-error-section li {
    margin-left: 8px;
    margin-bottom: 16px;
    color: #1074B7;
    line-height: 20px;
    font-family: NotoSansBold;
    cursor: pointer; }

.warning-icon {
  color: #BD0707;
  margin-right: 20px; }
