.replicable-item-content {
  display: flex;
  align-items: center;
  width: 90%; }

.replicable-container {
  width: 100%; }

.remove-button {
  margin-left: 10px;
  cursor: pointer; }

.red-x-icon {
  color: red;
  display: flex;
  width: 10%; }

.add-component {
  display: flex; }
