@font-face {
  font-family: 'NotoSans';
  src: url("../../Assets/Fonts/NotoSans-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBold';
  src: url("../../Assets/Fonts/NotoSans-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansItalic';
  src: url("../../Assets/Fonts/NotoSans-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBoldItalic';
  src: url("../../Assets/Fonts/NotoSans-BoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerif';
  src: url("../../Assets/Fonts/NotoSerif-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBold';
  src: url("../../Assets/Fonts/NotoSerif-Bold.ttf") format("truetype");
  font-style: normal; }

@font-face {
  font-family: 'NotoSerifItalic';
  src: url("../../Assets/Fonts/NotoSerif-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBoldItalic';
  src: url("../../Assets/Fonts/NotoSerif-BoldItalic.ttf") format("truetype"); }

@keyframes grow {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes slide-in-left {
  0% {
    opacity: 0;
    transform: translateX(-1rem); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes slide-down {
  0% {
    opacity: 0.5;
    transform: translateY(-1rem); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slide-down-small {
  0% {
    opacity: 0;
    transform: translateY(-10px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slide-up {
  0% {
    opacity: 0.5;
    transform: translateY(15px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in-from-none {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes slide-up-from-none {
  0% {
    opacity: 0;
    transform: translateY(15px); }
  50% {
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes bounce {
  50% {
    padding-top: 0.5rem; }
  100% {
    padding-top: 0; } }

.header__hamburger {
  margin-right: 15px;
  cursor: pointer; }
  @media screen and (min-width: 992px) {
    .header__hamburger:not(.mobile-view) {
      display: none; } }

.search-btn {
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  cursor: pointer;
  color: #ffffff;
  padding: 0.5rem !important; }
  .search-btn:hover {
    color: #d5e4f3; }

.search-bar {
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 0;
  padding: 0;
  border: none !important;
  font-size: 14px !important; }
  .search-bar:focus {
    outline: none !important;
    border: none  !important; }
  .search-bar.search-open {
    width: 300px;
    padding-left: 0.5rem; }

.dropdown-menu {
  padding: 0 !important; }

.dropdown-item {
  color: #1074b7;
  font-family: NotoSansBold; }
  .dropdown-item .dropdown-icon {
    margin-right: 10px; }
  .dropdown-item.active, .dropdown-item:active {
    background-color: #d5e4f3;
    color: #071522 !important; }

.toolbar-buttons button {
  padding: 10px !important; }

.toolbar-buttons .dropdown-menu button {
  padding: 5px 15px !important; }

.recent-searches-popup .dropdown-item {
  font-family: NotoSans !important;
  font-size: 14px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important; }
  .recent-searches-popup .dropdown-item svg {
    font-size: 20px !important; }
  .recent-searches-popup .dropdown-item.advanced-search-btn {
    border-top: 1px solid #dcdfe1;
    padding: 15px !important; }

.recent-searches-popup .small-info-bubble {
  background-color: #dcdfe1;
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
  color: #071522;
  border-radius: 3px;
  height: 22px; }
