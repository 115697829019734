@font-face {
  font-family: 'NotoSans';
  src: url("../../../../Assets/Fonts/NotoSans-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBold';
  src: url("../../../../Assets/Fonts/NotoSans-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansItalic';
  src: url("../../../../Assets/Fonts/NotoSans-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBoldItalic';
  src: url("../../../../Assets/Fonts/NotoSans-BoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerif';
  src: url("../../../../Assets/Fonts/NotoSerif-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBold';
  src: url("../../../../Assets/Fonts/NotoSerif-Bold.ttf") format("truetype");
  font-style: normal; }

@font-face {
  font-family: 'NotoSerifItalic';
  src: url("../../../../Assets/Fonts/NotoSerif-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBoldItalic';
  src: url("../../../../Assets/Fonts/NotoSerif-BoldItalic.ttf") format("truetype"); }

.active-content .contact-row {
  display: flex;
  margin-bottom: 20px; }
  .active-content .contact-row .contact-fields {
    display: flex;
    flex-grow: 1; }
    .active-content .contact-row .contact-fields .input-container {
      flex-basis: 30%; }
      .active-content .contact-row .contact-fields .input-container:not(:first-child) {
        margin-left: 38px; }
  .active-content .contact-row .x-icon {
    align-self: flex-end;
    width: 24px;
    height: 24px;
    margin: 6px 6px 6px 18px;
    color: #BD0707;
    cursor: pointer; }

.read-only-content .contact-row {
  margin-bottom: 20px; }
