@font-face {
  font-family: 'NotoSans';
  src: url("../../Assets/Fonts/NotoSans-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBold';
  src: url("../../Assets/Fonts/NotoSans-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansItalic';
  src: url("../../Assets/Fonts/NotoSans-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBoldItalic';
  src: url("../../Assets/Fonts/NotoSans-BoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerif';
  src: url("../../Assets/Fonts/NotoSerif-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBold';
  src: url("../../Assets/Fonts/NotoSerif-Bold.ttf") format("truetype");
  font-style: normal; }

@font-face {
  font-family: 'NotoSerifItalic';
  src: url("../../Assets/Fonts/NotoSerif-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBoldItalic';
  src: url("../../Assets/Fonts/NotoSerif-BoldItalic.ttf") format("truetype"); }

@keyframes grow {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes slide-in-left {
  0% {
    opacity: 0;
    transform: translateX(-1rem); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes slide-down {
  0% {
    opacity: 0.5;
    transform: translateY(-1rem); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slide-down-small {
  0% {
    opacity: 0;
    transform: translateY(-10px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slide-up {
  0% {
    opacity: 0.5;
    transform: translateY(15px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in-from-none {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes slide-up-from-none {
  0% {
    opacity: 0;
    transform: translateY(15px); }
  50% {
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes bounce {
  50% {
    padding-top: 0.5rem; }
  100% {
    padding-top: 0; } }

.ReactCollapse--collapse {
  transition: height 200ms; }

.collapsible-section .collapse-arrow, .collapsible-text-header-section .collapse-arrow {
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  color: #3498db; }

.collapsible-section .collapse-arrow-wrapper, .collapsible-text-header-section .collapse-arrow-wrapper {
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  height: 24px;
  cursor: pointer;
  margin-left: 10px; }

.collapsible-section .collapsible-section-header, .collapsible-text-header-section .collapsible-section-header {
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  font-family: NotoSans !important;
  font-size: 16px;
  height: 40px;
  -moz-transition: margin-bottom 0.2s;
  -o-transition: margin-bottom 0.2s;
  -webkit-transition: margin-bottom 0.2s;
  transition: margin-bottom 0.2s; }
  .collapsible-section .collapsible-section-header .section-delete-button, .collapsible-text-header-section .collapsible-section-header .section-delete-button {
    position: absolute;
    right: 0; }
  .collapsible-section .collapsible-section-header:hover, .collapsible-text-header-section .collapsible-section-header:hover {
    background-color: #eff0f1; }

.collapsible-section {
  border-bottom: 1px solid #dcdfe1; }
  .collapsible-section .collapse-arrow-wrapper {
    transform: rotate(-90deg); }
  .collapsible-section.open .collapse-arrow-wrapper {
    transform: rotate(0deg); }
  .collapsible-section.open .collapsible-section-header {
    margin-bottom: 10px !important; }

.collapsible-text-header-section {
  width: 100%; }
  .collapsible-text-header-section .collapsible-text-header {
    -moz-transition: margin-bottom 0.2s ease;
    -o-transition: margin-bottom 0.2s ease;
    -webkit-transition: margin-bottom 0.2s ease;
    transition: margin-bottom 0.2s ease;
    font-size: 14px;
    color: #3498db;
    cursor: pointer; }
    .collapsible-text-header-section .collapsible-text-header:hover {
      color: #2e5c88; }
      .collapsible-text-header-section .collapsible-text-header:hover .collapse-arrow {
        color: #2e5c88; }
  .collapsible-text-header-section.open .collapsible-text-header {
    margin-bottom: 15px; }
  .collapsible-text-header-section.open .collapse-arrow-wrapper {
    transform: rotate(-180deg); }

.form-list-section:not(:last-of-type) {
  margin-bottom: 15px !important; }

.collapse-arrow-wrapper {
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease; }
  .collapse-arrow-wrapper .collapse-arrow {
    color: #3498db; }
  .collapse-arrow-wrapper.open {
    transform: rotate(-180deg); }
