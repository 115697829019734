@font-face {
  font-family: 'NotoSans';
  src: url("../../Assets/Fonts/NotoSans-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBold';
  src: url("../../Assets/Fonts/NotoSans-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansItalic';
  src: url("../../Assets/Fonts/NotoSans-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBoldItalic';
  src: url("../../Assets/Fonts/NotoSans-BoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerif';
  src: url("../../Assets/Fonts/NotoSerif-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBold';
  src: url("../../Assets/Fonts/NotoSerif-Bold.ttf") format("truetype");
  font-style: normal; }

@font-face {
  font-family: 'NotoSerifItalic';
  src: url("../../Assets/Fonts/NotoSerif-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBoldItalic';
  src: url("../../Assets/Fonts/NotoSerif-BoldItalic.ttf") format("truetype"); }

@keyframes grow {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes slide-in-left {
  0% {
    opacity: 0;
    transform: translateX(-1rem); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes slide-down {
  0% {
    opacity: 0.5;
    transform: translateY(-1rem); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slide-down-small {
  0% {
    opacity: 0;
    transform: translateY(-10px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slide-up {
  0% {
    opacity: 0.5;
    transform: translateY(15px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in-from-none {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes slide-up-from-none {
  0% {
    opacity: 0;
    transform: translateY(15px); }
  50% {
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes bounce {
  50% {
    padding-top: 0.5rem; }
  100% {
    padding-top: 0; } }

.input-block--wrapper {
  padding-right: 1.5rem;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column; }
  .input-block--wrapper.no-padding-margin {
    margin: 0 !important;
    padding: 0 !important; }
    .input-block--wrapper.no-padding-margin .checkbox-block {
      margin: 0;
      padding: 0; }
  .input-block--wrapper .field-label {
    font-family: NotoSansBold; }
  .input-block--wrapper .k-textarea.text-area-normal-height textarea {
    height: 90px; }
  .input-block--wrapper .field-value:not(.checkbox):not(.k-switch):not(.k-radio-list) {
    font-family: NotoSans !important;
    border: 1px solid #dcdfe1 !important;
    background-color: #ffffff;
    min-height: 40px !important; }
    .input-block--wrapper .field-value:not(.checkbox):not(.k-switch):not(.k-radio-list):disabled {
      background-color: #f5f5f5; }
      .input-block--wrapper .field-value:not(.checkbox):not(.k-switch):not(.k-radio-list):disabled .k-icon {
        opacity: 0.5 !important; }
    .input-block--wrapper .field-value:not(.checkbox):not(.k-switch):not(.k-radio-list):not(.k-editor) {
      display: flex;
      align-items: center; }
    .input-block--wrapper .field-value:not(.checkbox):not(.k-switch):not(.k-radio-list).focused {
      outline: 1px solid #3498db !important;
      border: 1px solid rgba(52, 152, 219, 0.2) !important; }
    .input-block--wrapper .field-value:not(.checkbox):not(.k-switch):not(.k-radio-list).with-error {
      background-color: rgba(189, 7, 7, 0.1) !important; }
      .input-block--wrapper .field-value:not(.checkbox):not(.k-switch):not(.k-radio-list).with-error .k-icon {
        color: #bd0707 !important;
        opacity: 1 !important; }
    .input-block--wrapper .field-value:not(.checkbox):not(.k-switch):not(.k-radio-list).with-error:not(.focused) {
      outline: 1px solid #bd0707 !important;
      border: 1px solid rgba(189, 7, 7, 0.1) !important; }
  .input-block--wrapper .field-value {
    width: 100%; }
  .input-block--wrapper .small-input.field-value {
    width: 90px !important; }
  .input-block--wrapper .handles-focus .k-state-focused {
    outline: 1px solid #3498db !important; }
  .input-block--wrapper .checkbox-block {
    margin-top: 25px;
    margin-bottom: auto; }
    .input-block--wrapper .checkbox-block:hover .k-checkbox:not(:checked) {
      box-shadow: 0px 0px 3px 1px rgba(46, 92, 136, 0.1); }

.field-value.k-state-disabled,
.field-value .k-state-disabled {
  color: #071522 !important;
  opacity: 1 !important; }

.text-align-right input {
  text-align: right !important; }
