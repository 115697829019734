@font-face {
  font-family: 'NotoSans';
  src: url("../../Assets/Fonts/NotoSans-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBold';
  src: url("../../Assets/Fonts/NotoSans-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansItalic';
  src: url("../../Assets/Fonts/NotoSans-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBoldItalic';
  src: url("../../Assets/Fonts/NotoSans-BoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerif';
  src: url("../../Assets/Fonts/NotoSerif-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBold';
  src: url("../../Assets/Fonts/NotoSerif-Bold.ttf") format("truetype");
  font-style: normal; }

@font-face {
  font-family: 'NotoSerifItalic';
  src: url("../../Assets/Fonts/NotoSerif-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBoldItalic';
  src: url("../../Assets/Fonts/NotoSerif-BoldItalic.ttf") format("truetype"); }

@keyframes grow {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes slide-in-left {
  0% {
    opacity: 0;
    transform: translateX(-1rem); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes slide-down {
  0% {
    opacity: 0.5;
    transform: translateY(-1rem); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slide-down-small {
  0% {
    opacity: 0;
    transform: translateY(-10px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slide-up {
  0% {
    opacity: 0.5;
    transform: translateY(15px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in-from-none {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes slide-up-from-none {
  0% {
    opacity: 0;
    transform: translateY(15px); }
  50% {
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes bounce {
  50% {
    padding-top: 0.5rem; }
  100% {
    padding-top: 0; } }

.easy-popup-content-wrap {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  min-width: 200px;
  padding: 15px;
  font-size: 14px;
  line-height: 1.35 !important;
  position: relative; }
  .easy-popup-content-wrap .popup-caret {
    position: absolute;
    top: 0;
    left: -10px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #ffffff; }
