@font-face {
  font-family: 'NotoSans';
  src: url("../../Assets/Fonts/NotoSans-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBold';
  src: url("../../Assets/Fonts/NotoSans-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansItalic';
  src: url("../../Assets/Fonts/NotoSans-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBoldItalic';
  src: url("../../Assets/Fonts/NotoSans-BoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerif';
  src: url("../../Assets/Fonts/NotoSerif-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBold';
  src: url("../../Assets/Fonts/NotoSerif-Bold.ttf") format("truetype");
  font-style: normal; }

@font-face {
  font-family: 'NotoSerifItalic';
  src: url("../../Assets/Fonts/NotoSerif-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBoldItalic';
  src: url("../../Assets/Fonts/NotoSerif-BoldItalic.ttf") format("truetype"); }

@keyframes grow {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes slide-in-left {
  0% {
    opacity: 0;
    transform: translateX(-1rem); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes slide-down {
  0% {
    opacity: 0.5;
    transform: translateY(-1rem); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slide-down-small {
  0% {
    opacity: 0;
    transform: translateY(-10px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slide-up {
  0% {
    opacity: 0.5;
    transform: translateY(15px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in-from-none {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes slide-up-from-none {
  0% {
    opacity: 0;
    transform: translateY(15px); }
  50% {
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes bounce {
  50% {
    padding-top: 0.5rem; }
  100% {
    padding-top: 0; } }

.k-chart {
  background-color: transparent !important; }
  .k-chart .k-chart-surface > svg > g > path {
    fill-opacity: 0; }
  .k-chart text {
    font-size: 14px !important; }

.k-tile {
  background-color: #3498db;
  padding: 1px;
  border-radius: 5px;
  color: #fff;
  width: 200px;
  display: inline-block;
  vertical-align: top;
  margin: 0 10px;
  position: relative;
  height: fit-content; }
  .k-tile .top-wrapper {
    padding-bottom: 10px;
    float: left;
    width: 100%; }
  .k-tile .custom-icon {
    height: 2em;
    width: 2em;
    float: left;
    text-align: center;
    margin: 0.5em 0.5em 0; }
  .k-tile .value-wrapper {
    float: right;
    text-align: center;
    width: 115px;
    margin: 0.5em 0.5em 0; }
  .k-tile .bottom-wrapper {
    clear: both;
    background-color: #f5f5f5;
    border-radius: 0 0 4px 4px;
    font-size: 12px;
    padding: 8px 10px; }
  .k-tile .link-wrapper a {
    color: #3498db;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .k-tile .link-wrapper a:not(.dark-component-item):hover {
    color: #3498db !important;
    text-decoration: unset !important; }
  .k-tile .value-font {
    font-size: 2.5em; }
