@font-face {
  font-family: 'NotoSans';
  src: url("../../Assets/Fonts/NotoSans-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBold';
  src: url("../../Assets/Fonts/NotoSans-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansItalic';
  src: url("../../Assets/Fonts/NotoSans-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBoldItalic';
  src: url("../../Assets/Fonts/NotoSans-BoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerif';
  src: url("../../Assets/Fonts/NotoSerif-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBold';
  src: url("../../Assets/Fonts/NotoSerif-Bold.ttf") format("truetype");
  font-style: normal; }

@font-face {
  font-family: 'NotoSerifItalic';
  src: url("../../Assets/Fonts/NotoSerif-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBoldItalic';
  src: url("../../Assets/Fonts/NotoSerif-BoldItalic.ttf") format("truetype"); }

@keyframes grow {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes slide-in-left {
  0% {
    opacity: 0;
    transform: translateX(-1rem); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes slide-down {
  0% {
    opacity: 0.5;
    transform: translateY(-1rem); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slide-down-small {
  0% {
    opacity: 0;
    transform: translateY(-10px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slide-up {
  0% {
    opacity: 0.5;
    transform: translateY(15px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in-from-none {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes slide-up-from-none {
  0% {
    opacity: 0;
    transform: translateY(15px); }
  50% {
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes bounce {
  50% {
    padding-top: 0.5rem; }
  100% {
    padding-top: 0; } }

.page-card {
  margin-bottom: 2rem; }
  .page-card:not(.flat-border) {
    box-shadow: 0px 0px 3px 1px rgba(46, 92, 136, 0.1); }
  .page-card.flat-border {
    border: 1px solid #dcdfe1; }
  .page-card.no-bottom-margin {
    margin-bottom: 0px; }
  .page-card .page-card-row {
    margin: 0 !important; }
  .page-card:not(.no-section-borders) .page-card-row:not(:last-child) {
    border-bottom: 1px solid #dcdfe1; }
  .page-card:not(.no-section-borders) .card-section:not(:last-child) {
    border-right: 1px solid #dcdfe1; }
  .page-card.no-section-borders .page-card-row:not(:last-child) .card-section {
    padding-bottom: 0px; }
  .page-card .collapsible-title:hover .collapse-arrow {
    -moz-transition: background-color 0.2s;
    -o-transition: background-color 0.2s;
    -webkit-transition: background-color 0.2s;
    transition: background-color 0.2s;
    border-radius: 50%;
    background-color: #f5f5f5;
    color: #2e5c88 !important; }

.card-section {
  margin: 0 !important;
  padding: 15px 30px 30px 30px; }
  .card-section.centered-content {
    padding: 30px; }
  .card-section.input-section {
    padding-bottom: 0px; }
  .card-section.short {
    padding: 15px 30px; }
  .card-section.thin {
    padding-left: 15px;
    padding-right: 15px; }
  .card-section.no-top-padding {
    padding-top: 0px !important; }
  .card-section > h4 {
    display: flex;
    align-items: center; }
