@font-face {
  font-family: 'NotoSans';
  src: url("../../Assets/Fonts/NotoSans-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBold';
  src: url("../../Assets/Fonts/NotoSans-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansItalic';
  src: url("../../Assets/Fonts/NotoSans-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBoldItalic';
  src: url("../../Assets/Fonts/NotoSans-BoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerif';
  src: url("../../Assets/Fonts/NotoSerif-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBold';
  src: url("../../Assets/Fonts/NotoSerif-Bold.ttf") format("truetype");
  font-style: normal; }

@font-face {
  font-family: 'NotoSerifItalic';
  src: url("../../Assets/Fonts/NotoSerif-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBoldItalic';
  src: url("../../Assets/Fonts/NotoSerif-BoldItalic.ttf") format("truetype"); }

@keyframes grow {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes slide-in-left {
  0% {
    opacity: 0;
    transform: translateX(-1rem); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes slide-down {
  0% {
    opacity: 0.5;
    transform: translateY(-1rem); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slide-down-small {
  0% {
    opacity: 0;
    transform: translateY(-10px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slide-up {
  0% {
    opacity: 0.5;
    transform: translateY(15px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in-from-none {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes slide-up-from-none {
  0% {
    opacity: 0;
    transform: translateY(15px); }
  50% {
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes bounce {
  50% {
    padding-top: 0.5rem; }
  100% {
    padding-top: 0; } }

div.sidebar-menu {
  min-height: 100%;
  background-color: #3498db; }
  div.sidebar-menu .single-sidebar-item {
    padding: 12px 12px 12px 11px !important;
    border-bottom: none; }
  div.sidebar-menu a {
    color: #ffffff; }
  div.sidebar-menu .accordion {
    width: 100%; }
  div.sidebar-menu .navbar__item:not(:last-child) {
    border-bottom: #256fb6 solid 1px; }
  div.sidebar-menu .navbar__item a {
    color: #ffffff; }
    div.sidebar-menu .navbar__item a:hover {
      background: #1074b7;
      color: white; }
  div.sidebar-menu .btn-link {
    color: #ffffff;
    padding: 0; }
  div.sidebar-menu .btn:not(:disabled):not(.disabled) {
    width: 100%;
    text-align: left; }
  div.sidebar-menu .card {
    padding: 0px;
    background-color: transparent;
    color: #ffffff;
    width: 100%;
    margin-bottom: 0; }
    div.sidebar-menu .card:not(:last-child) {
      border-bottom: 1px #3498db solid !important; }
    div.sidebar-menu .card .collapse-arrow {
      color: white; }
    div.sidebar-menu .card .card-header {
      border-bottom: none !important;
      background-color: #3498db;
      text-transform: none !important;
      padding: 10px 30px;
      color: #ffffff; }
      div.sidebar-menu .card .card-header:hover {
        background: #1074b7 !important; }
    div.sidebar-menu .card .card-body {
      color: #ffffff;
      padding: 10px 40px; }
      div.sidebar-menu .card .card-body:hover {
        background: #0e5b8f !important; }
    div.sidebar-menu .card div.collapse:not(:last-child) {
      border-bottom: 1px #2e5c88 solid !important; }
  div.sidebar-menu button:focus {
    box-shadow: none; }
  div.sidebar-menu button .card-header {
    border-bottom: none; }

.main__sidebar {
  background-color: #3498db;
  z-index: 0;
  min-width: 0;
  width: 15px;
  margin: 0;
  border-right: 1px solid #dcdfe1;
  position: relative;
  grid-area: menu;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none; }
  .main__sidebar .navbar__content {
    display: none;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #3498db !important;
    color: #ffffff !important; }
  .main__sidebar .main-sidebar-toggler {
    opacity: 0.5;
    z-index: 9999; }
  .main__sidebar.showing {
    min-width: 17rem;
    width: 17rem; }
    .main__sidebar.showing .navbar__content {
      display: block; }
    .main__sidebar.showing .main-sidebar-toggler:not(.mobile-toggler) {
      opacity: 0; }
  @media screen and (max-width: 768px) {
    .main__sidebar {
      width: 0; } }
  .main__sidebar:hover .main-sidebar-toggler {
    opacity: 1 !important; }

.main__sidebar::-webkit-scrollbar {
  display: none; }

.main-card {
  background-color: #f5f5f5;
  grid-area: main-card;
  padding: 18px 30px;
  overflow-y: auto; }

.main-sidebar-layout {
  display: grid;
  grid-template-areas: 'menu main';
  grid-template-columns: auto 1fr;
  grid-template-rows: 100%; }
  .main-sidebar-layout.with-sub-header .main-card {
    padding: 0 !important;
    overflow-y: hidden; }

.main-sidebar-toggler {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.2);
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  background-color: #3498db;
  color: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 50%;
  position: absolute;
  top: 10rem;
  right: -1rem;
  z-index: 9999;
  cursor: pointer; }
  .main-sidebar-toggler:hover {
    color: #3498db;
    background-color: #ffffff;
    border-color: #ffffff;
    opacity: 1; }
  .main-sidebar-toggler.close-icon {
    transform: rotate(180deg); }
  @media screen and (max-width: 768px) {
    .main-sidebar-toggler {
      display: none; } }
