@font-face {
  font-family: 'NotoSans';
  src: url("../../Assets/Fonts/NotoSans-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBold';
  src: url("../../Assets/Fonts/NotoSans-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansItalic';
  src: url("../../Assets/Fonts/NotoSans-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBoldItalic';
  src: url("../../Assets/Fonts/NotoSans-BoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerif';
  src: url("../../Assets/Fonts/NotoSerif-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBold';
  src: url("../../Assets/Fonts/NotoSerif-Bold.ttf") format("truetype");
  font-style: normal; }

@font-face {
  font-family: 'NotoSerifItalic';
  src: url("../../Assets/Fonts/NotoSerif-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBoldItalic';
  src: url("../../Assets/Fonts/NotoSerif-BoldItalic.ttf") format("truetype"); }

@keyframes grow {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes slide-in-left {
  0% {
    opacity: 0;
    transform: translateX(-1rem); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes slide-down {
  0% {
    opacity: 0.5;
    transform: translateY(-1rem); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slide-down-small {
  0% {
    opacity: 0;
    transform: translateY(-10px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slide-up {
  0% {
    opacity: 0.5;
    transform: translateY(15px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in-from-none {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes slide-up-from-none {
  0% {
    opacity: 0;
    transform: translateY(15px); }
  50% {
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes bounce {
  50% {
    padding-top: 0.5rem; }
  100% {
    padding-top: 0; } }

.fixed-actions-bar {
  animation: slide-up-from-none 0.4s;
  border-top: 1px solid #dcdfe1;
  min-height: 4rem;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: #ffffff; }

.form-column {
  padding: 0 15px; }

.ant-drawer-body .modal-drawer-button-row {
  display: flex;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 0 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  border-top: 1px solid #dcdfe1;
  background-color: #ffffff; }

.admin-sub-header-layout {
  height: 100%;
  display: grid;
  grid-template-areas: 'admin-header' 'admin-main-content';
  grid-template-columns: 1fr;
  grid-template-rows: 92px auto; }
  .admin-sub-header-layout.with-no-subroutes {
    grid-template-rows: 75px auto; }
  .admin-sub-header-layout .admin-header {
    grid-area: admin-header; }
  .admin-sub-header-layout .admin-main-content {
    grid-area: admin-main-content;
    overflow-y: auto;
    height: 100%; }

.info-box {
  background-color: #f5f5f5;
  font-size: 12px;
  width: auto; }
