@font-face {
  font-family: 'NotoSans';
  src: url("../../../Assets/Fonts/NotoSans-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBold';
  src: url("../../../Assets/Fonts/NotoSans-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansItalic';
  src: url("../../../Assets/Fonts/NotoSans-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBoldItalic';
  src: url("../../../Assets/Fonts/NotoSans-BoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerif';
  src: url("../../../Assets/Fonts/NotoSerif-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBold';
  src: url("../../../Assets/Fonts/NotoSerif-Bold.ttf") format("truetype");
  font-style: normal; }

@font-face {
  font-family: 'NotoSerifItalic';
  src: url("../../../Assets/Fonts/NotoSerif-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBoldItalic';
  src: url("../../../Assets/Fonts/NotoSerif-BoldItalic.ttf") format("truetype"); }

.section {
  background-color: white;
  border-radius: 8px;
  border: 1px solid #DCDFE1;
  box-shadow: 0px 0px 3px 1px #2E5C881A;
  margin-bottom: 40px; }

.header {
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #DCDFE1;
  padding: 14px 32px; }
  .header .active-indicator {
    position: absolute;
    left: 0;
    top: 0;
    background-color: #3498DB;
    width: 6px;
    min-height: 100%;
    border-radius: 8px 0 0 0; }
  .header .title {
    font-weight: 500;
    font-size: 24px; }
  .header .title-active {
    color: #3498DB; }
  .header .optional {
    margin-left: 8px;
    font-weight: 500;
    font-size: 24px;
    color: #A6A6A6; }
  .header .edit-icon {
    width: 24px;
    height: 24px;
    margin-left: 14px;
    color: #3498DB;
    cursor: pointer; }
  .header .check-icon {
    width: 32px;
    height: 32px;
    padding: 6px;
    border-radius: 50%;
    margin-left: inherit;
    background-color: #28A74526; }
    .header .check-icon svg {
      width: 20px;
      height: 20px;
      color: #28A745;
      stroke: #28A745;
      stroke-width: 3px; }

.initial-state {
  cursor: pointer; }
  .initial-state:hover {
    background-color: #F7FAFD;
    border: 1px solid #95C9EC; }

.body {
  padding: 32px; }
  .body .initial-content {
    display: flex;
    align-items: center;
    padding: 8px 0px;
    cursor: pointer; }
    .body .initial-content .icon-container {
      width: 80px;
      height: 80px;
      border: 2px dashed #D5E4F3;
      border-radius: 50%;
      padding: 18px; }
      .body .initial-content .icon-container svg {
        width: 40px;
        height: 40px;
        color: #D5E4F3; }
    .body .initial-content p {
      overflow-wrap: break-word;
      white-space: normal;
      line-height: 17.5px;
      margin-left: 32px;
      margin-bottom: 0px;
      width: 408px;
      color: #2E5C88; }

.remove-icon {
  height: 24px;
  margin: 6px 6px 6px 18px;
  color: #BD0707;
  font-size: large;
  margin-left: auto; }
  .remove-icon:hover {
    cursor: pointer; }
