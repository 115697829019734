.report-questions .input-container {
  flex-basis: 100% !important; }

.report-questions-read ol {
  list-style: none;
  counter-reset: list-counter;
  padding-left: 0; }
  .report-questions-read ol li {
    counter-increment: list-counter;
    position: relative;
    padding-left: 1.5em; }
    .report-questions-read ol li::before {
      content: counter(list-counter) ".";
      font-weight: bold;
      position: absolute;
      left: 0; }
