.newReferralList {
  position: absolute;
  top: 110%;
  left: 0;
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 100%;
  border-radius: 3px; }
  .newReferralList li {
    padding: 12px 20px;
    cursor: pointer;
    border-bottom: 1px solid #eee; }
    .newReferralList li a {
      color: inherit; }
    .newReferralList li .linkText {
      display: block;
      text-decoration: none;
      color: inherit;
      cursor: pointer;
      transition: color 0.2s;
      padding: 10px 0; }
      .newReferralList li .linkText:hover {
        color: #3498DB; }

.buttonWrapper {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 500; }

.newReferralButton {
  display: flex;
  align-items: center;
  padding: 0;
  border-radius: 5px;
  height: 50px;
  border: 2px solid #3498DB;
  font-weight: 600;
  color: #3498DB; }

.newReferralText {
  padding: 0 20px; }

.arrowIcon {
  border-left: 2px solid #3498DB;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 10px; }
  .arrowIcon svg {
    width: 30px;
    height: 50px; }
