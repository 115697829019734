@font-face {
  font-family: 'NotoSans';
  src: url("../../Assets/Fonts/NotoSans-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBold';
  src: url("../../Assets/Fonts/NotoSans-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansItalic';
  src: url("../../Assets/Fonts/NotoSans-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBoldItalic';
  src: url("../../Assets/Fonts/NotoSans-BoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerif';
  src: url("../../Assets/Fonts/NotoSerif-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBold';
  src: url("../../Assets/Fonts/NotoSerif-Bold.ttf") format("truetype");
  font-style: normal; }

@font-face {
  font-family: 'NotoSerifItalic';
  src: url("../../Assets/Fonts/NotoSerif-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBoldItalic';
  src: url("../../Assets/Fonts/NotoSerif-BoldItalic.ttf") format("truetype"); }

@keyframes grow {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes slide-in-left {
  0% {
    opacity: 0;
    transform: translateX(-1rem); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes slide-down {
  0% {
    opacity: 0.5;
    transform: translateY(-1rem); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slide-down-small {
  0% {
    opacity: 0;
    transform: translateY(-10px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slide-up {
  0% {
    opacity: 0.5;
    transform: translateY(15px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in-from-none {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes slide-up-from-none {
  0% {
    opacity: 0;
    transform: translateY(15px); }
  50% {
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes bounce {
  50% {
    padding-top: 0.5rem; }
  100% {
    padding-top: 0; } }

.main__left {
  background-color: #ffffff;
  grid-area: navigation;
  z-index: 0;
  min-width: 0;
  width: 15px;
  margin: 0;
  border-right: 1px solid #dcdfe1;
  position: relative; }
  .main__left .navbar__content {
    padding: 1.5rem 0;
    display: none;
    overflow-y: auto;
    overflow-x: hidden; }
  .main__left .sidebar-toggler {
    opacity: 0.5;
    z-index: 9999; }
  .main__left.showing {
    min-width: 17rem;
    width: unset; }
    .main__left.showing .navbar__content {
      display: block; }
    .main__left.showing .sidebar-toggler:not(.mobile-toggler) {
      opacity: 0; }
  @media screen and (max-width: 768px) {
    .main__left {
      width: 0; } }
  .main__left:hover .sidebar-toggler {
    opacity: 1 !important; }

.main-card {
  background-color: #f5f5f5;
  grid-area: main-card;
  padding: 18px 30px;
  overflow-y: auto; }

.sidebar-layout {
  display: grid;
  grid-template-areas: 'navigation main-card';
  grid-template-columns: auto 1fr;
  grid-template-rows: 100%; }
  .sidebar-layout.with-sub-header .main-card {
    padding: 0 !important;
    overflow-y: hidden; }

.sidebar-toggler {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.2);
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  background-color: #ffffff;
  color: #3498db;
  border: 1px solid #f5f5f5;
  border-radius: 50%;
  position: absolute;
  top: 10rem;
  right: -1rem;
  cursor: pointer; }
  .sidebar-toggler:hover {
    color: #ffffff;
    background-color: #3498db;
    border-color: #3498db;
    opacity: 1; }
  .sidebar-toggler.close-icon {
    transform: rotate(180deg); }
  @media screen and (max-width: 768px) {
    .sidebar-toggler {
      display: none; } }
