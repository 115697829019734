@font-face {
  font-family: 'NotoSans';
  src: url("../../../Assets/Fonts/NotoSans-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBold';
  src: url("../../../Assets/Fonts/NotoSans-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansItalic';
  src: url("../../../Assets/Fonts/NotoSans-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBoldItalic';
  src: url("../../../Assets/Fonts/NotoSans-BoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerif';
  src: url("../../../Assets/Fonts/NotoSerif-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBold';
  src: url("../../../Assets/Fonts/NotoSerif-Bold.ttf") format("truetype");
  font-style: normal; }

@font-face {
  font-family: 'NotoSerifItalic';
  src: url("../../../Assets/Fonts/NotoSerif-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBoldItalic';
  src: url("../../../Assets/Fonts/NotoSerif-BoldItalic.ttf") format("truetype"); }

.active-content .title {
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  font-family: NotoSans;
  margin-bottom: 24px; }
  .active-content .title:not(:first-of-type) {
    margin-top: 40px; }
