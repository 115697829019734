@font-face {
  font-family: 'NotoSans';
  src: url("../../../Assets/Fonts/NotoSans-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBold';
  src: url("../../../Assets/Fonts/NotoSans-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansItalic';
  src: url("../../../Assets/Fonts/NotoSans-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBoldItalic';
  src: url("../../../Assets/Fonts/NotoSans-BoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerif';
  src: url("../../../Assets/Fonts/NotoSerif-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBold';
  src: url("../../../Assets/Fonts/NotoSerif-Bold.ttf") format("truetype");
  font-style: normal; }

@font-face {
  font-family: 'NotoSerifItalic';
  src: url("../../../Assets/Fonts/NotoSerif-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBoldItalic';
  src: url("../../../Assets/Fonts/NotoSerif-BoldItalic.ttf") format("truetype"); }

.dropdown-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
  position: relative; }
  .dropdown-container label {
    margin-left: 1px;
    margin-bottom: 8px;
    font-family: NotoSansBold; }
  .dropdown-container .required {
    color: #bd0707; }
  .dropdown-container .dropdown-input-container {
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 40px;
    position: relative; }
    .dropdown-container .dropdown-input-container.error {
      border-color: #bd0707; }
  .dropdown-container .multi-select-dropdown-input-container:focus {
    border: 1px solid  #3498DB; }
  .dropdown-container .dropdown {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    margin-top: 5px;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    position: absolute;
    z-index: 10;
    width: 100%;
    max-width: 300px;
    top: 100%;
    left: 0;
    transition: max-height 0.3s ease, opacity 0.3s ease; }
    .dropdown-container .dropdown.open {
      max-height: 300px;
      overflow-y: scroll;
      opacity: 1; }
    .dropdown-container .dropdown .multi-select-dropdown-option-item {
      font-family: NotoSans;
      padding: 10px;
      cursor: pointer;
      transition: background-color 0.2s;
      margin-bottom: 3px;
      margin-left: 3px; }
      .dropdown-container .dropdown .multi-select-dropdown-option-item:hover {
        background-color: #f0f0f0; }
      .dropdown-container .dropdown .multi-select-dropdown-option-item input[type="checkbox"] {
        margin-right: 8px; }
  .dropdown-container .hint {
    margin-left: 1px;
    font-size: 12px;
    color: #888;
    margin-top: 8px; }
