@font-face {
  font-family: 'NotoSans';
  src: url("../../../../Assets/Fonts/NotoSans-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBold';
  src: url("../../../../Assets/Fonts/NotoSans-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansItalic';
  src: url("../../../../Assets/Fonts/NotoSans-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBoldItalic';
  src: url("../../../../Assets/Fonts/NotoSans-BoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerif';
  src: url("../../../../Assets/Fonts/NotoSerif-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBold';
  src: url("../../../../Assets/Fonts/NotoSerif-Bold.ttf") format("truetype");
  font-style: normal; }

@font-face {
  font-family: 'NotoSerifItalic';
  src: url("../../../../Assets/Fonts/NotoSerif-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBoldItalic';
  src: url("../../../../Assets/Fonts/NotoSerif-BoldItalic.ttf") format("truetype"); }

.body {
  padding: 32px; }

.add-item-button {
  display: flex;
  cursor: pointer;
  color: #3498DB;
  align-items: center; }
  .add-item-button span {
    font-size: 14px;
    font-weight: 700;
    font-family: NotoSansBold;
    line-height: 20px; }
  .add-item-button svg {
    margin: 0px 8px;
    height: 24px;
    width: 24px; }
  .add-item-button:first-child {
    margin-bottom: 40px; }
