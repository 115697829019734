@font-face {
  font-family: 'NotoSans';
  src: url("../../../Assets/Fonts/NotoSans-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBold';
  src: url("../../../Assets/Fonts/NotoSans-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansItalic';
  src: url("../../../Assets/Fonts/NotoSans-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBoldItalic';
  src: url("../../../Assets/Fonts/NotoSans-BoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerif';
  src: url("../../../Assets/Fonts/NotoSerif-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBold';
  src: url("../../../Assets/Fonts/NotoSerif-Bold.ttf") format("truetype");
  font-style: normal; }

@font-face {
  font-family: 'NotoSerifItalic';
  src: url("../../../Assets/Fonts/NotoSerif-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBoldItalic';
  src: url("../../../Assets/Fonts/NotoSerif-BoldItalic.ttf") format("truetype"); }

@keyframes grow {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes slide-in-left {
  0% {
    opacity: 0;
    transform: translateX(-1rem); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes slide-down {
  0% {
    opacity: 0.5;
    transform: translateY(-1rem); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slide-down-small {
  0% {
    opacity: 0;
    transform: translateY(-10px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slide-up {
  0% {
    opacity: 0.5;
    transform: translateY(15px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in-from-none {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes slide-up-from-none {
  0% {
    opacity: 0;
    transform: translateY(15px); }
  50% {
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes bounce {
  50% {
    padding-top: 0.5rem; }
  100% {
    padding-top: 0; } }

.collapsible-filter-bar {
  border-radius: 5px;
  margin-bottom: 5px;
  background-color: white;
  box-shadow: 0px 0px 3px 1px rgba(46, 92, 136, 0.1);
  padding: 10px; }

.filter-bar-wrapper {
  padding-left: 10px;
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: start;
  position: relative;
  padding-right: 20px; }
  .filter-bar-wrapper .filter-bar-search-icon {
    color: #2e5c88;
    height: 20.59px;
    width: 20.59px;
    position: absolute;
    left: 0;
    top: 5px; }
  .filter-bar-wrapper .filter-bar-search-input {
    height: 100%;
    width: 100%;
    min-width: 150px;
    padding: 10px;
    margin-left: 20px;
    margin-bottom: 5px;
    border: 1px solid #dcdfe1; }
  .filter-bar-wrapper .filter-bar-search-input:focus-visible, .filter-bar-wrapper .filter-bar-search-input:focus {
    border: 1px solid #3498db !important; }
  .filter-bar-wrapper .k-button {
    box-shadow: none !important;
    border-color: #dcdfe1 !important;
    border: 1px solid #dcdfe1;
    font-size: 12px;
    margin-left: 20px;
    width: 200px;
    margin-top: 10px; }
    .filter-bar-wrapper .k-button:focus, .filter-bar-wrapper .k-button:hover {
      box-shadow: none !important;
      color: #071522 !important;
      background-color: #d5e4f3 !important; }
  .filter-bar-wrapper .filter-dropdown-arrow:not(.with-filter-number) {
    position: absolute;
    right: 5px; }
  .filter-bar-wrapper .filter-count {
    background-color: #2e5c88;
    color: #ffffff;
    font-size: 12px;
    margin: 0 4px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1; }

.filter-dropdown-popup-wrap {
  min-width: 200px; }

.filter-dropdown-popup {
  min-width: 200px;
  overflow-y: hidden;
  overflow-x: visible;
  width: auto;
  background-color: #ffffff;
  box-shadow: 0px 1px 3px 1px rgba(46, 92, 136, 0.25); }

.filter-chips {
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 10px 0; }
  .filter-chips .filter-chips-content {
    margin-top: -5px; }
    .filter-chips .filter-chips-content > * {
      margin-top: 5px; }
  .filter-chips .filter-chip {
    -moz-transition: box-shadow 0.2s;
    -o-transition: box-shadow 0.2s;
    -webkit-transition: box-shadow 0.2s;
    transition: box-shadow 0.2s;
    display: flex;
    align-items: center;
    background-color: #2e5c88;
    line-height: 1.25;
    padding: 4px 10px;
    color: #ffffff;
    border-radius: 30px;
    margin-right: 10px;
    cursor: pointer; }
    .filter-chips .filter-chip:hover {
      box-shadow: 0px 1px 3px 1px rgba(46, 92, 136, 0.25); }
    .filter-chips .filter-chip .filter-chip-close {
      height: 100%;
      float: right;
      display: flex;
      align-items: center; }

.dropdown-options-filter {
  height: 30px;
  border: 1px solid #dcdfe1;
  border-radius: 0px;
  outline: none;
  font-size: 12px;
  font-family: NotoSans;
  padding-left: 10px;
  width: 100%; }

.dropdown-options-container {
  font-size: 12px;
  height: auto; }
  .dropdown-options-container .dropdown-options-wrapper {
    overflow-x: hidden; }
  .dropdown-options-container .dropdown-option-wrapper {
    cursor: pointer;
    padding: 0.5rem 15px; }
    .dropdown-options-container .dropdown-option-wrapper:hover, .dropdown-options-container .dropdown-option-wrapper:focus {
      background-color: #d5e4f3; }
    .dropdown-options-container .dropdown-option-wrapper.active {
      color: #3498db; }
    .dropdown-options-container .dropdown-option-wrapper .checkbox-wrapper {
      padding-top: 0px !important;
      height: 100%; }
      .dropdown-options-container .dropdown-option-wrapper .checkbox-wrapper .k-checkbox-label {
        width: 100%; }

.k-dialog .filter-bar-wrapper,
.page-card .filter-bar-wrapper {
  box-shadow: none !important;
  border: 1px solid #dcdfe1; }
