@font-face {
  font-family: 'NotoSans';
  src: url("../../../../Assets/Fonts/NotoSans-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBold';
  src: url("../../../../Assets/Fonts/NotoSans-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansItalic';
  src: url("../../../../Assets/Fonts/NotoSans-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBoldItalic';
  src: url("../../../../Assets/Fonts/NotoSans-BoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerif';
  src: url("../../../../Assets/Fonts/NotoSerif-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBold';
  src: url("../../../../Assets/Fonts/NotoSerif-Bold.ttf") format("truetype");
  font-style: normal; }

@font-face {
  font-family: 'NotoSerifItalic';
  src: url("../../../../Assets/Fonts/NotoSerif-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBoldItalic';
  src: url("../../../../Assets/Fonts/NotoSerif-BoldItalic.ttf") format("truetype"); }

.k-datepicker {
  min-width: 300px;
  max-width: 500px;
  width: 100%;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  padding: 4px;
  background-color: white; }

.date-picker-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }

.date-picker-label {
  font-family: NotoSansBold;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px; }
  .date-picker-label .required {
    color: #bd0707; }
