@font-face {
  font-family: 'NotoSans';
  src: url("../../Assets/Fonts/NotoSans-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBold';
  src: url("../../Assets/Fonts/NotoSans-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansItalic';
  src: url("../../Assets/Fonts/NotoSans-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBoldItalic';
  src: url("../../Assets/Fonts/NotoSans-BoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerif';
  src: url("../../Assets/Fonts/NotoSerif-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBold';
  src: url("../../Assets/Fonts/NotoSerif-Bold.ttf") format("truetype");
  font-style: normal; }

@font-face {
  font-family: 'NotoSerifItalic';
  src: url("../../Assets/Fonts/NotoSerif-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBoldItalic';
  src: url("../../Assets/Fonts/NotoSerif-BoldItalic.ttf") format("truetype"); }

@keyframes grow {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes slide-in-left {
  0% {
    opacity: 0;
    transform: translateX(-1rem); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes slide-down {
  0% {
    opacity: 0.5;
    transform: translateY(-1rem); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slide-down-small {
  0% {
    opacity: 0;
    transform: translateY(-10px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slide-up {
  0% {
    opacity: 0.5;
    transform: translateY(15px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in-from-none {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes slide-up-from-none {
  0% {
    opacity: 0;
    transform: translateY(15px); }
  50% {
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes bounce {
  50% {
    padding-top: 0.5rem; }
  100% {
    padding-top: 0; } }

.pillbar__wrapper {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #ffffff; }
  .pillbar__wrapper a {
    font-family: NotoSansBold;
    color: inherit !important;
    display: block !important;
    font-weight: unset !important; }
  .pillbar__wrapper .navpill__content {
    padding: 0 15px; }

.secondary-nav-pillbar-tab {
  border-left: solid 1px #dcdfe1;
  border-top: solid 1px #dcdfe1;
  border-right: solid 1px #dcdfe1;
  border-radius: 3px;
  margin-right: 2px;
  padding: 6px 6px 0 6px; }
  .secondary-nav-pillbar-tab.active {
    border-top: solid 3px #3498db; }

.secondary-nav-pillbar .navpill--underline {
  width: 0%;
  transition: width 0.2s ease; }

.secondary-nav-pillbar .secondary-nav-pill {
  font-family: NotoSansBold;
  color: #1074b7;
  position: relative;
  margin-right: 2rem;
  height: 28px;
  align-self: flex-end;
  font-size: 16px;
  align-items: center; }
  .secondary-nav-pillbar .secondary-nav-pill:hover, .secondary-nav-pillbar .secondary-nav-pill:focus, .secondary-nav-pillbar .secondary-nav-pill.active {
    color: #071522; }
  .secondary-nav-pillbar .secondary-nav-pill.active .navpill--underline {
    height: 5px;
    width: 100%;
    background-color: #3498db;
    display: block;
    position: absolute;
    bottom: 0; }

.navpill__wrapper {
  font-family: NotoSansBold;
  height: 100%;
  cursor: pointer;
  border-radius: 0.15rem;
  text-align: center;
  white-space: nowrap;
  position: relative;
  color: #acb3bd;
  align-items: stretch; }
  .navpill__wrapper .dropdown-arrow {
    margin-left: 10px; }
  .navpill__wrapper > button.btn {
    all: unset !important;
    height: 100%;
    display: block !important; }
  .navpill__wrapper:hover, .navpill__wrapper:focus {
    color: #ffffff; }
  .navpill__wrapper .navpill--underline {
    width: 0%;
    transition: width 0.2s ease; }
  .navpill__wrapper.active {
    color: #ffffff;
    background-color: #071522; }
    .navpill__wrapper.active .navpill--underline {
      box-shadow: 0px 0px 3px 1px rgba(46, 92, 136, 0.1);
      height: 4px;
      width: 100%;
      background-color: #d5e4f3;
      display: block;
      position: absolute;
      top: 0;
      left: 0; }
    .navpill__wrapper.active .navpill__content {
      color: #ffffff !important; }
    .navpill__wrapper.active .dropdown-menu {
      background-color: #071522 !important;
      color: #ffffff !important; }
  .navpill__wrapper .dropdown-menu {
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.2);
    background-color: #0d263e;
    margin: 0 !important;
    border: none !important;
    min-width: 100%; }
    .navpill__wrapper .dropdown-menu .dropdown-item {
      border-color: #071522;
      color: #acb3bd; }
      .navpill__wrapper .dropdown-menu .dropdown-item.active, .navpill__wrapper .dropdown-menu .dropdown-item:hover, .navpill__wrapper .dropdown-menu .dropdown-item:focus {
        background-color: #071522 !important;
        color: #ffffff !important;
        color: #ffffff; }
