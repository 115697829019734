@font-face {
  font-family: 'NotoSans';
  src: url("../../../../Assets/Fonts/NotoSans-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBold';
  src: url("../../../../Assets/Fonts/NotoSans-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansItalic';
  src: url("../../../../Assets/Fonts/NotoSans-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBoldItalic';
  src: url("../../../../Assets/Fonts/NotoSans-BoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerif';
  src: url("../../../../Assets/Fonts/NotoSerif-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBold';
  src: url("../../../../Assets/Fonts/NotoSerif-Bold.ttf") format("truetype");
  font-style: normal; }

@font-face {
  font-family: 'NotoSerifItalic';
  src: url("../../../../Assets/Fonts/NotoSerif-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBoldItalic';
  src: url("../../../../Assets/Fonts/NotoSerif-BoldItalic.ttf") format("truetype"); }

.active-content .active-row {
  display: flex;
  margin-bottom: 20px; }
  .active-content .active-row .active-fields {
    display: flex;
    flex-grow: 1; }
    .active-content .active-row .active-fields .input-container,
    .active-content .active-row .active-fields .dropdown-container,
    .active-content .active-row .active-fields .date-picker-wrapper {
      flex-basis: 33%; }
      .active-content .active-row .active-fields .input-container .date-time-picker-row,
      .active-content .active-row .active-fields .dropdown-container .date-time-picker-row,
      .active-content .active-row .active-fields .date-picker-wrapper .date-time-picker-row {
        display: block;
        margin-top: 0px; }
      .active-content .active-row .active-fields .input-container:not(:first-child),
      .active-content .active-row .active-fields .dropdown-container:not(:first-child),
      .active-content .active-row .active-fields .date-picker-wrapper:not(:first-child) {
        margin-left: 38px; }
    .active-content .active-row .active-fields .date-picker-wrapper {
      justify-content: start; }
  .active-content .active-row .x-icon {
    align-self: flex-end;
    width: 24px;
    height: 24px;
    margin: 6px 6px 6px 18px;
    color: #BD0707;
    cursor: pointer; }
  .active-content .active-row .street-address {
    width: 100%; }

.read-only-content .row {
  margin-bottom: 20px; }
