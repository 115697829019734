@font-face {
  font-family: 'NotoSans';
  src: url("../../Assets/Fonts/NotoSans-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBold';
  src: url("../../Assets/Fonts/NotoSans-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansItalic';
  src: url("../../Assets/Fonts/NotoSans-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBoldItalic';
  src: url("../../Assets/Fonts/NotoSans-BoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerif';
  src: url("../../Assets/Fonts/NotoSerif-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBold';
  src: url("../../Assets/Fonts/NotoSerif-Bold.ttf") format("truetype");
  font-style: normal; }

@font-face {
  font-family: 'NotoSerifItalic';
  src: url("../../Assets/Fonts/NotoSerif-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBoldItalic';
  src: url("../../Assets/Fonts/NotoSerif-BoldItalic.ttf") format("truetype"); }

@keyframes grow {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes slide-in-left {
  0% {
    opacity: 0;
    transform: translateX(-1rem); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes slide-down {
  0% {
    opacity: 0.5;
    transform: translateY(-1rem); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slide-down-small {
  0% {
    opacity: 0;
    transform: translateY(-10px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slide-up {
  0% {
    opacity: 0.5;
    transform: translateY(15px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in-from-none {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes slide-up-from-none {
  0% {
    opacity: 0;
    transform: translateY(15px); }
  50% {
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes bounce {
  50% {
    padding-top: 0.5rem; }
  100% {
    padding-top: 0; } }

.navbar__item {
  -moz-transition: background-color 0.2s ease;
  -o-transition: background-color 0.2s ease;
  -webkit-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
  font-family: NotoSansBold;
  display: flex;
  align-items: center;
  padding: 10px 30px;
  cursor: pointer;
  font-size: 14px;
  color: #1074b7;
  min-height: 40px; }
  .navbar__item .nav-label-container {
    flex-grow: 1;
    display: flex; }
  .navbar__item .navbar__item--icon {
    padding-right: 10px;
    color: #1074b7; }
  .navbar__item .MuiSvgIcon-colorSecondary {
    color: #1074b7 !important; }
  .navbar__item:hover {
    background-color: #f5f5f5; }
    .navbar__item:hover:not(.active) {
      color: #1074b7; }
  .navbar__item.active {
    z-index: 1;
    opacity: 1;
    background-color: #d5e4f3;
    color: #071522 !important; }
    .navbar__item.active .MuiSvgIcon-colorSecondary {
      color: #3498db !important; }
    .navbar__item.active .navbar__item--icon {
      padding-right: .75rem;
      color: #071522; }
  .navbar__item .navigation-index {
    font-family: NotoSans;
    font-size: 12px !important;
    padding-left: 30px; }
