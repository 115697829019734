@font-face {
  font-family: 'NotoSans';
  src: url("../../../Assets/Fonts/NotoSans-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBold';
  src: url("../../../Assets/Fonts/NotoSans-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansItalic';
  src: url("../../../Assets/Fonts/NotoSans-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBoldItalic';
  src: url("../../../Assets/Fonts/NotoSans-BoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerif';
  src: url("../../../Assets/Fonts/NotoSerif-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBold';
  src: url("../../../Assets/Fonts/NotoSerif-Bold.ttf") format("truetype");
  font-style: normal; }

@font-face {
  font-family: 'NotoSerifItalic';
  src: url("../../../Assets/Fonts/NotoSerif-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBoldItalic';
  src: url("../../../Assets/Fonts/NotoSerif-BoldItalic.ttf") format("truetype"); }

@keyframes grow {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes slide-in-left {
  0% {
    opacity: 0;
    transform: translateX(-1rem); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes slide-down {
  0% {
    opacity: 0.5;
    transform: translateY(-1rem); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slide-down-small {
  0% {
    opacity: 0;
    transform: translateY(-10px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slide-up {
  0% {
    opacity: 0.5;
    transform: translateY(15px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in-from-none {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes slide-up-from-none {
  0% {
    opacity: 0;
    transform: translateY(15px); }
  50% {
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes bounce {
  50% {
    padding-top: 0.5rem; }
  100% {
    padding-top: 0; } }

.k-scheduler {
  box-shadow: 0px 0px 3px 1px rgba(46, 92, 136, 0.1) !important;
  border-style: none; }
  .k-scheduler .k-scheduler-layout-flex {
    overflow: unset !important; }
  .k-scheduler .k-scheduler-day-view .k-scheduler-cell.k-slot-cell {
    padding: 0 !important; }
  .k-scheduler .k-scheduler-day-view .k-scheduler-body .k-scheduler-group {
    height: 25px !important; }
  .k-scheduler .k-scheduler-day-view .k-scheduler-body .k-scheduler-cell.k-heading-cell.k-side-cell {
    border-bottom: none !important; }
  .k-scheduler .k-scheduler-day-view .k-scheduler-body .k-scheduler-cell.k-heading-cell.k-side-cell.k-major-cell {
    border-top: 1px solid #dcdfe1 !important;
    margin-top: -1px !important; }
  .k-scheduler .k-scheduler-day-view .k-scheduler-head .k-scheduler-group {
    min-height: 60px !important; }
  .k-scheduler .k-scheduler-day-view .k-event-template span {
    margin-bottom: 2px; }
  .k-scheduler .k-scheduler-monthview .k-scheduler-body .k-scheduler-cell.k-slot-cell {
    height: 150px; }
    .k-scheduler .k-scheduler-monthview .k-scheduler-body .k-scheduler-cell.k-slot-cell .k-nav-day {
      position: absolute;
      right: 15px;
      top: 15px; }
  .k-scheduler .k-scheduler-monthview .k-heading-cell {
    height: 45px !important; }
  .k-scheduler .k-scheduler-monthview .today-slot .k-nav-day {
    width: 25px;
    height: 25px;
    background-color: #3498db !important;
    color: #ffffff !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    right: 10px !important;
    top: 12px !important; }
  .k-scheduler .k-scheduler-cell.k-slot-cell {
    padding: 15px; }
  .k-scheduler .k-side-cell {
    font-family: NotoSansBold;
    min-width: unset !important;
    width: 80px !important;
    padding: 5px 5px 0 0 !important;
    align-items: flex-start !important; }
  .k-scheduler .calendar-date-display {
    min-width: 225px; }
  .k-scheduler .k-scheduler-head {
    background-color: #f5f5f5; }
    .k-scheduler .k-scheduler-head .k-nav-day {
      font-weight: unset !important;
      font-family: NotoSansBold;
      font-size: 16px; }
    .k-scheduler .k-scheduler-head .k-week-heading-cell {
      justify-content: left;
      height: 80px; }
      .k-scheduler .k-scheduler-head .k-week-heading-cell.today-cell {
        background-color: #1074b7 !important;
        color: #ffffff !important; }
  .k-scheduler .month-view-item {
    transform: translateY(44px) !important;
    display: flex;
    align-items: center; }
    .k-scheduler .month-view-item .k-event-template {
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 0 10px !important; }
  .k-scheduler .k-event {
    background-color: #2e5c88 !important;
    color: #ffffff !important;
    padding-right: 0 !important;
    animation: fade-in-from-none 0.4s; }
    .k-scheduler .k-event.before-now {
      opacity: .7; }
    .k-scheduler .k-event .k-event-template {
      font-size: 12px;
      line-height: 1.25 !important;
      width: 100%;
      height: 100%;
      padding: 0 !important; }
    .k-scheduler .k-event .k-event-actions {
      display: none !important; }
    .k-scheduler .k-event .event-separator {
      position: absolute;
      right: 0;
      height: 100%;
      width: 5px;
      background-color: #ffffff; }
  .k-scheduler .k-slot-cell:last-child,
  .k-scheduler .k-heading-cell:last-child {
    border-right: none; }
  .k-scheduler .k-heading-cell {
    padding: 0 15px;
    line-height: 1.25 !important;
    display: flex;
    align-items: center; }
  .k-scheduler .k-scheduler-toolbar {
    padding: 15px; }
  .k-scheduler .k-current-time {
    background-color: #fcab10;
    z-index: 100; }
    .k-scheduler .k-current-time.k-current-time-arrow-right {
      background-color: transparent;
      border-left-color: #fcab10; }
  .k-scheduler .k-nonwork-hour {
    background-color: #ffffff; }
