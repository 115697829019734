@font-face {
  font-family: 'NotoSans';
  src: url("../../../Assets/Fonts/NotoSans-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBold';
  src: url("../../../Assets/Fonts/NotoSans-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansItalic';
  src: url("../../../Assets/Fonts/NotoSans-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSansBoldItalic';
  src: url("../../../Assets/Fonts/NotoSans-BoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerif';
  src: url("../../../Assets/Fonts/NotoSerif-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBold';
  src: url("../../../Assets/Fonts/NotoSerif-Bold.ttf") format("truetype");
  font-style: normal; }

@font-face {
  font-family: 'NotoSerifItalic';
  src: url("../../../Assets/Fonts/NotoSerif-Italic.ttf") format("truetype"); }

@font-face {
  font-family: 'NotoSerifBoldItalic';
  src: url("../../../Assets/Fonts/NotoSerif-BoldItalic.ttf") format("truetype"); }

.file-uploader {
  width: 70%; }
  .file-uploader .filepond--drop-label {
    border: 1px solid #DCDFE1;
    background-color: #F5F9FA; }
    .file-uploader .filepond--drop-label label {
      min-width: 100%; }
  .file-uploader .filepond--root {
    margin-bottom: -2px !important; }
  .file-uploader .filepond--list-scroller, .file-uploader .filepond--credits {
    display: none; }

.file-item {
  display: flex;
  align-items: center;
  padding: 9px;
  border: 1px solid #DCDFE1;
  margin-bottom: -1px; }
  .file-item .file-icon {
    width: 32px;
    height: 32px;
    color: #DCDFE1;
    padding-right: 8px; }
  .file-item .filename {
    font-size: 14px;
    line-height: 17.5px;
    color: #071522;
    margin-bottom: 2px; }
  .file-item .loading-text {
    font-size: 10px;
    line-height: 11.5px;
    margin-bottom: 0;
    color: #07152280; }
  .file-item .success-text {
    font-size: 10px;
    line-height: 11.5px;
    margin-bottom: 0;
    color: #28A745; }
  .file-item .delete-icon {
    margin-left: auto;
    width: 16px;
    height: 16px;
    color: #071522;
    cursor: pointer; }
  .file-item .loading-icon {
    margin-left: auto;
    width: 20px;
    height: 20px;
    color: #07152280; }

.format-error-panel {
  display: flex;
  position: relative;
  border-width: 0.5px 0.5px 0.5px 4px;
  border-color: #BD0707;
  border-style: solid;
  border-radius: 4px;
  background-color: #F8E6E6;
  margin-bottom: 24px;
  padding: 16px; }
  .format-error-panel p:first-of-type {
    font-weight: 700;
    font-family: NotoSansBold;
    line-height: 20px;
    margin-bottom: 4px; }
  .format-error-panel p:last-of-type {
    line-height: 17.5px;
    margin-bottom: 0px; }
  .format-error-panel .close-icon {
    margin-left: auto;
    width: 16px;
    height: 16px;
    color: #071522;
    cursor: pointer; }
