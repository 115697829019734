.active-content .settings-container {
  display: flex; }
  .active-content .settings-container .input-container {
    flex-basis: 33%; }

.read-only-content .contract {
  display: flex;
  align-items: center;
  width: 622px;
  padding: 9px;
  border: 1px solid #DCDFE1; }
  .read-only-content .contract:not(:first-child) {
    margin-top: 12px; }
  .read-only-content .contract svg {
    color: #DCDFE1;
    width: 32px;
    height: 32px;
    margin-right: 8px; }
  .read-only-content .contract span {
    font-size: 14px;
    color: #071522; }
